import { graphql } from 'gatsby';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { getPageLink } from '../constants';
import { PageContentType } from '../context/PageContentType';

/**
 * Generic error page
 *
 * @param props
 * @constructor
 */
const GenericErrorPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const state = useContext(GlobalStateContext);

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.error'),
      path: getPageLink(props.pageContext.lang, 'ERROR_PAGE'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'ERROR_PAGE')}
      pageTitle={t('errorPage.title')}
    >
      <div className="ontario-row">
        <div className="mdmp__homepage ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <h1>{t('error.' + state.errorPage.title)}</h1>

          <div className="ontario-alert ontario-alert--error" id="error-holder">
            <div className="ontario-alert__header">
              <div className="ontario-alert__header-icon">
                <svg
                  className="ontario-icon"
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 24 24"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <use href="#ontario-icon-alert-error"></use>
                </svg>
              </div>
              <h2 className="ontario-alert__header-title ontario-h4">
                {t('error.thereIsProblem')}
              </h2>
            </div>
            <div className="ontario-alert__body">
              {t(state.errorPage.errorDescription)}
            </div>
          </div>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default GenericErrorPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
